<template>
  <div>
    <v-overlay :value="overlay" absolute>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card flat tile>
      <v-toolbar flat color="white">
        <v-toolbar-title>
          <v-icon class="mb-1">mdi-invoice-edit-outline</v-icon>
          Comercial invoices: {{ fechaCoordSel }}
        </v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <!-- <v-btn
          v-if="hcoordActiva == 1"
          small
          color="green"
          dark
          @click="abrirCoordinacion(selHcoordId)"
          ><v-icon small left>mdi-door-open</v-icon>abrir</v-btn
        >
        <v-btn
          v-if="hcoordActiva == 0"
          small
          color="green"
          dark
          @click="cerrarCoordinacion(selHcoordId)"
          ><v-icon small left>mdi-close</v-icon>cerrar</v-btn
        > -->
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-toolbar flat color="white">
        <v-col cols="3">
          <v-select
            label="Coordinaciones Activas"
            :items="listaHcoord"
            small
            item-value="HCOORD_ID"
            item-text="HCOORD_FECHA"
            v-model="selHcoordId"
            @change="seleccionarFechaCoordAbierta(selHcoordId)"
          ></v-select>
        </v-col>

        <v-col cols="4">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="fechaCoord"
                v-on="on"
                label="Coordinaciones Cerradas"
              ></v-text-field>
            </template>
            <v-date-picker
              ref="selFecha"
              :reactive="true"
              v-model="fechaCoord"
              :allowed-dates="getFechasCoord"
              :max="new Date().toISOString().substr(0, 10)"
              min="2004-01-01"
              elevation="2"
              locale="es-ec"
              :close-on-content-click="true"
              @change="seleccionarFechaCoordCerrada"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="2">
          <v-autocomplete
            :items="hcoordGuias"
            label="Guias aéreas"
            item-text="AWB"
            item-value="AWB"
            v-model="guiaSel"
            @change="cargarDatosGuia()"
          >
            <template v-slot:item="data">
              <template>
                <v-list-item-content>
                  <v-list-item-title
                    v-html="data.item.CLIENTE_NOMBRE"
                  ></v-list-item-title>

                  <v-list-item-subtitle
                    v-html="data.item.AWB"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
          <!-- <v-btn small @click="dialogHcoord = true" color="info" class="mx-2"
            ><v-icon left>mdi-plus</v-icon>Nueva Coordinación</v-btn
          > -->
        </v-col>
      </v-toolbar>
    </v-card>

    <v-row>
      <v-col cols="12">
        <!-- <v-row>
          <v-col cols="12" class="px-2 mx-4 pr-6 py-4">
            <v-data-table
              :headers="headerAl"
              :options.sync="options"
              :items="hcoordAl"
              :itemsPerPage="itemsPerPageAl"
              :server-items-length="totalItemsAl"
              class="elevation-1"
              hide-default-footer
            ></v-data-table>
          </v-col>
        </v-row> -->
        <v-row>
          <v-col cols="6" class="px-1 pr-4 py-4">
            <v-data-table
              :headers="headerDetalle"
              :options.sync="options"
              :items="detalleGuia"
              :itemsPerPage="itemsPerPageGuia"
              :server-items-length="totalItemsGuia"
              class="elevation-1"
              v-model="detalleSel"
              item-key="DETALLE_ID"
              show-select
            >
              <template v-slot:item.opciones="{ item }">
                <v-btn
                  target="_blank"
                  small
                  title="coordinar"
                  text
                  @click="crearInvoiceHaawb(item)"
                  ><v-icon small>mdi-eye</v-icon></v-btn
                >
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="6">
            <!-- <v-btn small dark color="green" @click="crearInvoiceHaawb()"
              ><v-icon>mdi-plus</v-icon>Crear Invoice</v-btn
            > -->
            <h4 class="py-4 px-2">Comercial Invoices</h4>
            <v-data-table
              :headers="headerInvCab"
              :options.sync="options"
              :items="invoiceCab"
              :itemsPerPage="itemsPerPageGuia"
              :server-items-length="totalItemsGuia"
              class="elevation-1"
            >
              <template v-slot:item.opciones="{ item }">
                <v-btn
                  target="_blank"
                  small
                  title="editar invoice"
                  text
                  @click="abrirDialogInvoice(item)"
                  ><v-icon small>mdi-pencil</v-icon></v-btn
                >
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- dialogo invoice -->
    <v-dialog v-model="dialogInvoice" max-width="450px" persistent scrollable>
      <v-form ref="formInvoice">
        <v-card>
          <v-toolbar flat color="blue" fixed height="40" dark>
            <v-toolbar-title>
              <v-icon class="mx-1">mdi-invoice-edit-outline</v-icon>
              Comercial Invoice
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn small text @click="dialogInvoice = !dialogInvoice"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-toolbar>
          <v-card-text>
            <v-row class="pa-0">
              <v-col cols="12" class="py-0">
                <v-text-field
                  readonly
                  :rules="requiredRule"
                  class="mt-0"
                  label="Numero factura"
                  v-model="datosInvoice.NUM_FAC"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="pa-0">
              <v-col cols="12" class="py-0">
                <v-text-field
                  readonly
                  :rules="requiredRule"
                  class="mt-0"
                  label="Fecha"
                  v-model="datosInvoice.FECHA_EMISION"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="pa-0">
              <v-col cols="12" class="py-0">
                <v-text-field
                  :rules="requiredRule"
                  class="mt-0"
                  label="Handling fee"
                  v-model="datosInvoice.HANDLING_FEE"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="pa-0">
              <v-col cols="6" class="py-4">
                <v-btn @click="modificarInvoice" small dark color="green"
                  ><v-icon small>mdi-content-save</v-icon>Guardar</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import moment from "moment";
import Vue from "vue";

export default {
  name: "Invoices",
  components: {},
  props: {},

  data: () => ({
    requiredRule: [(v) => !!v || "El campo es requerido"],
    options: {},
    rail: true,
    drawer: null,
    overlay: false,
    datosForm: {
      HCOORD_ID: "",
      HCOORD_FECHA: "",
      HCOORD_ACTIVO: "",
    },
    listaHcoord: [],
    listaAutocomplete: [],
    seleccion: [],

    selHcoordId: "",

    itemsPerPage: 50,
    totalItems: 10,
    currentPage: 1,
    pageCount: 0,

    hcoordAl: [],
    hcoordGuias: [],
    itemsPerPageAl: 50,
    totalItemsAl: 10,
    itemsPerPageGuia: 15,
    totalItemsGuia: 10,
    headerHcoord: [
      { text: "opciones", value: "opcionesHc" },
      { text: "ID", value: "HCOORD_ID" },
      { text: "FECHA", value: "HCOORD_FECHA" },
      { text: "ESTADO", value: "HCOORD_ACTIVO" },
    ],

    headerDetalle: [
      { text: "Opcion", value: "opciones", width: 50 },
      { text: "Hawb", value: "NUM_GUIA_FINCA", width: 80 },
      { text: "Cliente", value: "SCLIENTE_NOMBRE", width: 160 },
      { text: "Finca", value: "FINCA_NOMBRE", width: 160 },
    ],
    detalleGuia: [],
    detalleSel: [],

    headerInvCab: [
      { text: "Opcion", value: "opciones", width: 50 },
      { text: "Factura", value: "NUM_FAC", width: 80 },
      { text: "Handling fee", value: "HANDLING_FEE", width: 160 },
    ],
    invoiceCab: [],
    menu: false,
    requiredRules: [(v) => !!v || "This field is required"],
    fechaCoord: "",
    listaCoordCerradas: [],
    fechasHabilitadas: [],
    fechaCoordSel: "",
    hcoordActiva: "",
    dialogHcoord: false,

    ///dialog hcoord
    soloActivos: false,
    nuevaFechaCoord: "",
    desde: "",
    hasta: "",
    hcFechas: [],
    hcBuscarFechas: false,
    guiaSel: "",
    datosInvoice: {},
    dialogInvoice: false,
  }),
  computed: {
    ...mapState("master", ["loadingBtn"]),
    dateRangeText() {
      return this.hcFechas.join(" - ");
    },
  },
  methods: {
    ...mapActions("master", ["requestApi", "alertNotification"]),
    ...mapMutations("master", [
      "setUrl",
      "setOverlay",
      "setMenu",
      "setTitleToolbar",
      "setLoadingTable",
    ]),
    ...mapActions("master", ["requestApi", "alertNotification"]),
    cargarDatos() {
      this.setLoadingTable(true);
      this.setUrl("api/lista-hcoord");
      this.requestApi({
        method: "GET",
        data: {
          todo: "0",
        },
      })
        .then((res) => {
          this.listaHcoord = res.data.hcoord;
          this.setLoadingTable(false);
        })
        .catch(() => {})
        .finally(() => {
          this.setLoadingTable(false);
        });
    },
    cargarDatosHcoord(hcoordId) {
      this.setLoadingTable(true);
      this.setUrl("api/datos-hcoord");
      this.requestApi({
        method: "GET",
        data: {
          hcoord_id: hcoordId,
        },
      })
        .then((res) => {
          this.hcoordAl = res.data.al_coord;
          this.hcoordGuias = res.data.guias_coord;
          //this.setLoadingTable(false);
        })
        .catch(() => {})
        .finally(() => {
          this.setLoadingTable(false);
        });
    },
    // confirmarAbrirGuia(awbNum) {
    //   Vue.swal({
    //     html: "Desea abrir esta guía ?",
    //     icon: "warning",
    //     showCancelButton: true,
    //     confirmButtonText: "Aceptar",
    //     cancelButtonText: "Cancelar",
    //     ...this.paramAlertQuestion,
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       this.setUrl("api/abrir-guia");
    //       this.requestApi({
    //         method: "POST",
    //         data: {
    //           awb: awbNum,
    //         },
    //       }).then((res) => {
    //         console.log(res);
    //         this.alertNotification({ param: { html: res.data.msg } });
    //       });
    //     }
    //   });
    // },

    cargarCoordCerradas() {
      this.setUrl("api/lista-hcoord");
      this.requestApi({
        method: "GET",
        data: {
          todo: "1",
        },
      })
        .then((res) => {
          this.listaCoordCerradas = res.data.hcoord;
          //console.log(this.listaCoordCerradas);
          this.fechasHabilitadas = [];
          for (let i = 0; i < this.listaCoordCerradas.length; i++) {
            this.fechasHabilitadas.push(
              this.listaCoordCerradas[i].HCOORD_FECHA
            );
          }
          this.setLoadingTable(false);
        })
        .catch(() => {})
        .finally(() => {
          this.setLoadingTable(false);
        });
    },
    getFechasCoord(val) {
      if (this.fechasHabilitadas.indexOf(val) !== -1) {
        return true;
      } else {
        return false;
      }
    },
    seleccionarFechaCoordAbierta() {
      this.fechaCoordSel = this.listaHcoord.HCOORD_FECHA;

      for (let i = 0; this.listaHcoord.length; i++) {
        if (this.listaHcoord[i].HCOORD_ID == this.selHcoordId) {
          this.fechaCoordSel = this.listaHcoord[i].HCOORD_FECHA;
          this.hcoordActiva = this.listaHcoord[i].HCOORD_ACTIVO;
          break;
        }
      }

      this.cargarDatosHcoord(this.selHcoordId);
      this.fechaCoord = "";
    },
    seleccionarFechaCoordCerrada() {
      for (let i = 0; this.listaCoordCerradas.length; i++) {
        if (this.listaCoordCerradas[i].HCOORD_FECHA == this.fechaCoord) {
          this.selHcoordId = this.listaCoordCerradas[i].HCOORD_ID;
          this.hcoordActiva = this.listaCoordCerradas[i].HCOORD_ACTIVO;
          break;
        }
      }
      this.fechaCoordSel = this.fechaCoord;
      this.cargarDatosHcoord(this.selHcoordId);
      this.menu = false;
    },

    cargarDatosGuia() {
      this.setUrl("api/datos-guia-invoice");
      this.requestApi({
        method: "POST",
        data: {
          awb: this.guiaSel,
        },
      })
        .then((res) => {
          this.detalleGuia = res.data;
          this.setLoadingTable(false);
        })
        .catch(() => {})
        .finally(() => {
          this.setLoadingTable(false);
        });
    },
    cargarDatosInvoices(item) {
      this.setUrl("api/datos-guia-invoice");
      this.requestApi({
        method: "POST",
        data: {
          awb: this.guiaSel,
        },
      })
        .then((res) => {
          this.detalleGuia = res.data;
          this.setLoadingTable(false);
        })
        .catch(() => {})
        .finally(() => {
          this.setLoadingTable(false);
        });
    },

    crearInvoiceHaawb(item) {
      this.setUrl("api/crear-invoices-hawb");
      this.requestApi({
        method: "POST",
        data: {
          detalle_id: item.DETALLE_ID,
        },
      })
        .then((res) => {
          this.invoiceCab = res.data.invoices_cab;
          this.setLoadingTable(false);
        })
        .catch(() => {})
        .finally(() => {
          this.setLoadingTable(false);
        });
    },
    abrirDialogInvoice(item) {
      this.dialogInvoice = true;
      this.datosInvoice = item;
    },

    modificarInvoice() {
      this.setUrl("api/modificar-invoice-cab");
      this.requestApi({
        method: "POST",
        data: {
          datosInvoice: this.datosInvoice,
        },
      })
        .then((res) => {
          this.invoiceCab = res.data.invoices_cab;
          this.dialogInvoice = false;
          this.setLoadingTable(false);
          this.crearInvoiceHaawb({ DETALLE_ID: this.datosInvoice.DETALLE_ID });
          this.alertNotification({ param: { html: res.data.msg } });
        })
        .catch((res) => {})
        .finally(() => {
          this.setLoadingTable(false);
        });
    },
  },

  mounted() {
    document.title = "Sistema de carga";
    this.setTitleToolbar("GUIAS AEREAS");
    this.cargarDatos();
    this.cargarCoordCerradas();
  },
};
</script>
